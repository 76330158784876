/*
 * @Author: dongjia
 * @Date: 2021-08-17 17:43:30
 * @LastEditTime: 2021-08-17 17:46:56
 * @LastEditors: aleaner
 * @Description: 活动管理接口
 * @FilePath: \saas-admin-vue\src\modules\community-admin\api\group\activity-admin.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

// 获取活动列表
export const activityList = data => {
  return api({
    url: "/admin/circle/activity/index",
    method: "post",
    data
  });
};

// 新增活动
export const addActivity = data => {
  return api({
    url: "/admin/circle/activity/addActivity",
    method: "post",
    data
  });
};

// 移除活动
export const delActivity = data => {
  return api({
    url: "/admin/circle/activity/delete",
    method: "post",
    data
  });
};

// 获取活动弹窗数据
export const activityOptions = data => {
  return api({
    url: "/admin/circle/activity/all",
    method: "post",
    data
  });
};