<!--
 * @Author: dongjia
 * @Date: 2021-08-17 17:35:49
 * @LastEditTime: 2021-08-18 11:16:39
 * @LastEditors: aleaner
 * @Description: 小组活动管理列表
 * @FilePath: \saas-admin-vue\src\modules\community-admin\views\group\group-activity-list.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page">
    <el-button
      class="top-btn"
      type="primary"
      size="small"
      @click="handleAddActivity"
      >新增
    </el-button>
    <list-filter :filterForm="filterForm" :uploadFilter="ok" />
    <div class="list-content" style="margin-top: 20px" v-loading="loading">
      <div class="list-btn">
        <el-button type="danger" size="small" @click="handleDel"
          >移除
        </el-button>
      </div>
      <el-table
        :data="tbody"
        class="thead-light"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="80" fixed="right">
          <template slot-scope="scope">
            <el-button-group>
              <el-button size="small" @click="deleteRow(scope.row)" type="text"
                >移除</el-button
              >
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            :sortable="th.sortable"
          >
            <template slot-scope="scope">
              <div v-if="th.prop === 'join'">
                {{
                  `${scope.row.activity_users_count}/${scope.row.join_limit}`
                }}
              </div>
              <div v-else-if="th.prop === 'activity_status_text'">
                <span
                  :class="[
                    'status-dot',
                    scope.row.activity_status_text === '进行中'
                      ? 'green'
                      : 'grey',
                  ]"
                ></span>
                {{ scope.row[th.prop] | placeholder }}
              </div>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
    <ActivitySelector
      v-model="openActivitySelector"
      :selected="tbody"
      @listChange="handleChange"
    />
  </div>
</template>

<script>
import listFilter from "../../components/group/ActivitysFilter";
import Pagination from "@/base/components/Default/Pagination";
import ActivitySelector from "../../components/group/ActivitySelector";
import {
  activityList,
  addActivity,
  delActivity,
} from "../../api/group/activity-admin";
export default {
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: "名称", prop: "name", minWidth: 160 },
        { label: "报名人数", prop: "join", minWidth: 110 },
        {
          label: "活动时间",
          prop: "activity_time_text",
          minWidth: 280,
          sortable: true,
        },
        { label: "状态", prop: "activity_status_text", minWidth: 110 },
      ],
      //筛选对象
      filterForm: {
        group_id: this.$route.params.id,
        status: -1, // 活动状态
        name: "", // 名称
        page_size: 15, //每页多少条数据
      },
      pageData: {
        page_size: 15,
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      openActivitySelector: false,
    };
  },
  methods: {
    // 获取品牌列表
    getActivityList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      activityList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.selectedActivitys = data.user_ids;
          // this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 新增活动
    handleAddActivity() {
      this.openActivitySelector = true;
    },
    // 获取选择活动数据
    handleChange(val) {
      this.loading = true;
      let activity_id = [];
      val.forEach((el) => {
        activity_id.push(el.id);
      });
      addActivity({ activity_id, group_id: this.$route.params.id })
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.refreshList();
        });
    },
    // 批量移除
    handleDel() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据移除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete(this.selectArr);
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表移除
    deleteRow(row) {
      this.$msgbox
        .confirm("确定要将此数据移除吗？", "提示", {
          type: "error",
        })
        .then((res) => {
          this.handleDelete(row.id);
        })
        .catch((err) => {});
    },
    // 数据移除
    handleDelete(id) {
      this.loading = true;
      delActivity({ id, group_id: this.$route.params.id })
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getActivityList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    refreshList() {
      this.getActivityList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {};
      data.id = e.id;
      data[key] = e[key];
      this.loading = true;
      if (key == "is_recommend") {
        // 更新推荐状态
        updateRecommend(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else if (key === "is_show") {
        // 更新显示状态
        updateShow(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else {
        // 更新排序状态
        updateSort(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      }
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getGroupList(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getActivityList(pageData);
    },
  },
  created() {
    // this.getPidList(); //获取上级组织列表
    this.getActivityList(this.filterForm); //获取列表数据
  },
  components: {
    listFilter,
    Pagination,
    ActivitySelector,
  },
};
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }
  }
}
.list-icon {
  width: 60px;
  height: 60px;
}
.black-Activity {
  display: flex;
  align-items: center;
  .black-tag {
    margin-left: 10px;
    width: 44px;
    height: 28px;
    background-color: rgb(85, 85, 85);
    font-size: 12px;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
